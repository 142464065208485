<template>
  <menu-principal>
    <v-container fluid>

      <v-row>
        <v-col cols="12">
          <titulo-pagina>{{ tituloPagina }}</titulo-pagina>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" v-if="!permissaoPagina">
          <bloco-erro>Acesso Negado</bloco-erro>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" v-if="permissaoPagina && !existemPacotes">
          <bloco-erro>Não há pacotes cadastrados</bloco-erro>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" v-if="permissaoPagina && existemPacotes">
          <v-card>
            <v-card-text>
              <v-tabs v-model="tabs" color="orange darken-5">
                <v-tabs-slider color="orange"></v-tabs-slider>
                <v-tab color="orange"> Dados do Cliente</v-tab>
                <v-tab color="orange"> Dados da Locação</v-tab>
                <v-tabs-items v-model="tabs">
                  <v-tab-item :key="0">
                    <v-row class="mt-3">
                      <v-col cols="12">
                        <v-text-field
                            dense
                            v-model.trim="formulario.nome"
                            label="Nome do Cliente"
                            :error-messages="erros.nome"
                            prepend-inner-icon="mdi-face-man"
                            outlined
                            counter
                            maxlength="100"
                            :append-icon="(!!erros.nome && iconeErro) || ''"
                        />
                      </v-col>
                    </v-row><!-- Nome -->
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            v-model.trim="formulario.email"
                            label="Email do Cliente"
                            :error-messages="erros.email"
                            prepend-inner-icon="mdi-email"
                            outlined
                            counter
                            maxlength="100"
                            :append-icon="(!!erros.email && iconeErro) || ''"
                        />
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            v-model.trim="formulario.cpf"
                            label="CPF do Cliente"
                            :error-messages="erros.cpf"
                            prepend-inner-icon="mdi-card-account-details"
                            outlined
                            :append-icon="(!!erros.cpf && iconeErro) || ''"
                            v-mask="'###.###.###-##'"
                        />
                      </v-col>
                    </v-row><!-- Email / CPF -->
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            v-model.trim="formulario.rg"
                            label="RG do Cliente"
                            :error-messages="erros.rg"
                            prepend-inner-icon="mdi-card-account-details"
                            outlined
                            :append-icon="(!!erros.rg && iconeErro) || ''"
                            v-mask="'##.###.###-#'"
                        />
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            v-model.trim="formulario.dataNascimento"
                            label="Data de Nascimento"
                            :error-messages="erros.dataNascimento"

                            prepend-inner-icon="mdi-calendar"
                            outlined
                            :append-icon="(!!erros.dataNascimento && iconeErro) || ''"
                            v-mask="'##/##/####'"
                        />
                      </v-col>
                    </v-row><!-- RG / Data Nascimento -->
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            v-model.trim="formulario.ddd"
                            label="DDD"
                            :error-messages="erros.ddd"
                            prepend-inner-icon="mdi-phone"
                            outlined
                            :append-icon="(!!erros.ddd && iconeErro) || ''"
                            v-mask="'##'"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            v-model.trim="formulario.telefone"
                            label="Telefone"
                            :error-messages="erros.telefone"
                            prepend-inner-icon="mdi-phone"
                            outlined
                            :append-icon="(!!erros.telefone && iconeErro) || ''"
                            v-mask="mascaraTelefone"
                        />
                      </v-col>
                    </v-row><!-- DDD / Telefone -->

                    <v-row>
                      <v-col cols="12">
                        <span class="font-weight-bold font-size-16">Endereço do Locatário Cliente</span>
                      </v-col>
                    </v-row><!-- Título  -->

                    <v-row class="mt-2">
                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            v-model.trim="formulario.cep"
                            label="CEP da Residência"
                            :error-messages="erros.cep"
                            prepend-inner-icon="mdi-map-marker"
                            outlined
                            :append-icon="(!!erros.cep && iconeErro) || ''"
                            v-mask="'#####-###'"
                            @keyup="buscarCepLocatario()"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            v-model.trim="formulario.numeroResidencia"
                            label="Número da Residência"
                            :error-messages="erros.numeroResidencia"
                            prepend-inner-icon="mdi-home"
                            outlined
                            counter
                            maxlength="50"
                            :append-icon="(!!erros.numeroResidencia && iconeErro) || ''"
                        />
                      </v-col>
                    </v-row><!-- CEP / Número -->
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            v-model.trim="formulario.complemento"
                            label="Complemento"
                            :error-messages="erros.complemento"
                            prepend-inner-icon="mdi-home"
                            outlined
                            counter
                            maxlength="50"
                            :append-icon="(!!erros.complemento && iconeErro) || ''"
                        />
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            v-model.trim="formulario.logradouro"
                            label="Logradouro"
                            :error-messages="erros.logradouro"
                            prepend-inner-icon="mdi-home"
                            outlined
                            counter
                            maxlength="100"
                            :append-icon="(!!erros.logradouro && iconeErro) || ''"
                        />
                      </v-col>
                    </v-row><!-- Complemento / Logradouro -->
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            v-model.trim="formulario.bairro"
                            label="Bairro"
                            :error-messages="erros.bairro"
                            prepend-inner-icon="mdi-home"
                            outlined
                            counter
                            maxlength="100"
                            :append-icon="(!!erros.bairro && iconeErro) || ''"
                        />
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            v-model.trim="formulario.cidade"
                            label="Cidade"
                            :error-messages="erros.cidade"
                            prepend-inner-icon="mdi-map"
                            outlined
                            counter
                            maxlength="100"
                            :append-icon="(!!erros.cidade && iconeErro) || ''"
                        />
                      </v-col>
                    </v-row><!-- Bairro / Cidade -->
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-select
                            dense
                            v-model="formulario.uf"
                            label="UF"
                            :error-messages="erros.uf"
                            :items="itensSelect.ufs"
                            item-text="label"
                            item-value="id"
                            prepend-inner-icon="mdi-map"
                            outlined
                        />
                      </v-col>
                    </v-row><!-- UF -->

                    <v-row class="ma-5 float-right">
                      <v-btn
                          dark
                          color="orange"
                          @click="tabs = 1">
                        Próximo passo
                        <v-icon>mdi-arrow-right-bold</v-icon>
                      </v-btn>
                    </v-row><!-- Botão Próximo -->
                  </v-tab-item>
                  <v-tab-item :key="1">
                    <v-row class="mt-3">
                      <v-col cols="12" md="6">
                        <v-select
                            dense
                            v-model.trim="formulario.tipoContrato"
                            label="Tipo de Locação"
                            :error-messages="erros.tipoContrato"
                            item-text="label"
                            item-value="value"
                            :items="[{value: 'comercial', label: 'Comercial'}, {value: 'residencial', label: 'Residêncial'}]"
                            prepend-inner-icon="mdi-home"
                            outlined
                            :append-icon="(!!erros.tipoContrato && iconeErro) || ''"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            v-model.trim="formulario.cepLocacao"
                            label="CEP do Imóvel"
                            :error-messages="erros.cepLocacao"
                            prepend-inner-icon="mdi-map-marker"
                            outlined
                            :append-icon="(!!erros.cepLocacao && iconeErro) || ''"
                            v-mask="'#####-###'"
                            @keyup="buscarCepLocacao()"
                        />
                      </v-col>
                    </v-row><!-- Tipo de Locação / CEP -->
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            v-model.trim="formulario.numeroResidenciaLocacao"
                            label="Número do Imóvel"
                            :error-messages="erros.numeroResidenciaLocacao"

                            prepend-inner-icon="mdi-home"
                            outlined
                            counter
                            maxlength="50"
                            :append-icon="(!!erros.numeroResidenciaLocacao && iconeErro) || ''"
                        />
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            v-model.trim="formulario.complementoLocacao"
                            label="Complemento"
                            :error-messages="erros.complementoLocacao"
                            prepend-inner-icon="mdi-home"
                            outlined
                            counter
                            maxlength="50"
                            :append-icon="(!!erros.complementoLocacao && iconeErro) || ''"
                        />
                      </v-col>
                    </v-row><!-- Número do Imóvel / Complemento -->
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            v-model.trim="formulario.logradouroLocacao"
                            label="Logradouro do Imóvel"
                            :error-messages="erros.logradouroLocacao"

                            prepend-inner-icon="mdi-home"
                            outlined
                            counter
                            maxlength="100"
                            :append-icon="(!!erros.logradouroLocacao && iconeErro) || ''"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            v-model.trim="formulario.bairroLocacao"
                            label="Bairro do Imóvel"
                            :error-messages="erros.bairroLocacao"

                            prepend-inner-icon="mdi-home"
                            outlined
                            counter
                            maxlength="100"
                            :append-icon="(!!erros.bairroLocacao && iconeErro) || ''"
                        />
                      </v-col>
                    </v-row><!-- Logradouro / Bairro -->
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            v-model.trim="formulario.cidadeLocacao"
                            label="Cidade do Imóvel"
                            :error-messages="erros.cidadeLocacao"

                            prepend-inner-icon="mdi-map"
                            outlined
                            counter
                            maxlength="100"
                            :append-icon="(!!erros.cidadeLocacao && iconeErro) || ''"
                        />
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-select
                            v-model="formulario.ufLocacao"
                            label="UF do Imóvel"
                            :error-messages="erros.ufLocacao"
                            :items="itensSelect.ufs"
                            item-text="label"
                            item-value="id"
                            dense
                            prepend-inner-icon="mdi-map"
                            outlined
                        />
                      </v-col>
                    </v-row><!-- Cidade / UF -->
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            v-model.trim="formulario.valorAluguel"
                            label="Valor do Aluguel"
                            :error-messages="erros.valorAluguel"
                            prepend-inner-icon="mdi-cash"
                            outlined
                            :append-icon="(!!erros.valorAluguel && iconeErro) || ''"
                            v-money="formulario.valorAluguel !== null && configMonetario"
                        />
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            v-model.trim="formulario.valorCondominio"
                            label="Valor do Condomínio"
                            :error-messages="erros.valorCondominio"

                            prepend-inner-icon="mdi-cash"
                            outlined
                            :append-icon="(!!erros.valorCondominio && iconeErro) || ''"
                            v-money="formulario.valorCondominio !== null && configMonetario"
                        />
                      </v-col>
                    </v-row><!-- Valor do Aluguel / Valor do Condomínio -->
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            v-model.trim="formulario.valorAgua"
                            label="Valor do Conta de Água"
                            :error-messages="erros.valorAgua"

                            prepend-inner-icon="mdi-cash"
                            outlined
                            :append-icon="(!!erros.valorAgua && iconeErro) || ''"
                            v-money="formulario.valorAgua !== null && configMonetario"
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            v-model.trim="formulario.valorLuz"
                            label="Valor do Conta de Luz"
                            :error-messages="erros.valorLuz"
                            prepend-inner-icon="mdi-cash"
                            outlined
                            :append-icon="(!!erros.valorLuz && iconeErro) || ''"
                            v-money="formulario.valorLuz !== null && configMonetario"
                        />
                      </v-col>
                    </v-row><!-- Valor da Água / Valor da Luz -->
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            v-model.trim="formulario.valorIptu"
                            label="Valor do IPTU (mensal)"
                            :error-messages="erros.valorIptu"

                            prepend-inner-icon="mdi-cash"
                            outlined
                            :append-icon="(!!erros.valorIptu && iconeErro) || ''"
                            v-money="formulario.valorIptu !== null && configMonetario"
                        />
                      </v-col>
                    </v-row><!-- Valor do IPTU -->
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-select
                            dense
                            v-model="formulario.pacoteId"
                            label="Pacote"
                            :error-messages="erros.pacoteId"
                            :items="itensSelect.pacotes"
                            item-text="label"
                            item-value="valor"
                            prepend-inner-icon="mdi-package-variant"
                            outlined
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-select
                            dense
                            v-model="formulario.taxaSetup"
                            label="Taxa Setup"
                            :error-messages="erros.taxaSetup"
                            :items="pacoteAtual?.taxasSetup ?? []"
                            :item-text="(itm) => String('R$ '+parseFloat(itm.valor).toFixed(2)).replace('.',',')"
                            item-value="id"
                            prepend-inner-icon="mdi-cash"
                            outlined
                            :disabled="!formulario.pacoteId"
                        />
                      </v-col>
                    </v-row><!-- Pacote / Taxa Setup -->
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            :value="valorTotal"
                            label="Total Anual das Despesas"
                            prepend-inner-icon="mdi-cash"
                            outlined
                            v-money="configMonetario"
                            background-color="grey lighten-3"
                            color="var(--cor-primaria-100)"
                            readonly
                        />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                            dense
                            :value="valorTotalComPacote"
                            label="Total a Pagar"
                            background-color="grey lighten-3"
                            color="var(--cor-primaria-100)"
                            prepend-inner-icon="mdi-cash"
                            outlined
                            v-money="configMonetario"
                            readonly
                        />
                      </v-col>
                    </v-row><!-- Total Anual / Total a Pagar -->

                    <v-row class="ma-5">
                      <v-col cols="4" md="4">
                        <v-btn
                            class="float-left"
                            dark color="orange"
                            @click="tabs = 0">
                          <v-icon>mdi-arrow-left-bold</v-icon>
                          Voltar
                        </v-btn>
                      </v-col>

                      <v-col align="right" class="ml-auto" cols="4" md="8">
                        <v-btn
                            :block="blockBtns"
                            :class="{'mr-4': !blockBtns,'mb-4': blockBtns}"
                            dark
                            depressed
                            color="orange"
                            @click="confirmarSaida()"
                        >Ir para Listagem
                          <v-icon right>mdi-table</v-icon>
                        </v-btn>

                        <v-btn
                            :block="blockBtns"
                            dark
                            depressed
                            color="var(--cor-primaria-100)"
                            @click="submit()"
                        >{{ tipoSubmit === "cadastrar" ? "Registrar" : "Editar" }}
                          <v-icon right>mdi-send-check</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                </v-tabs-items>
              </v-tabs>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </menu-principal>
</template>

<script>
import MenuPrincipal from "../../components/MenuPrincipal";
import TituloPagina from "../../components/TituloPagina";
import BlocoErro from "../../components/BlocoErro";
import mixinGeral from "../../mixin/geral";
import clientesServices from "../../services/clientes";
import viaCepServices from "../../services/viaCep";
import brasilApiCep from "@/services/brasilApiCep";
import helpers from "@/assets/js/helpers";

export default {
  name: "Formulario",
  mixins: [mixinGeral],
  components: {
    MenuPrincipal,
    TituloPagina,
    BlocoErro,
  },
  data() {
    return {
      tabs: null,
      clienteEncontrado: true,
      idCliente: null,
      idEmpresa: null,
      formulario: {
        nome: null,
        email: null,
        cpf: null,
        rg: null,
        dataNascimento: null,
        ddd: null,
        telefone: null,
        cep: null,
        numeroResidencia: null,
        logradouro: null,
        bairro: null,
        cidade: null,
        uf: null,
        valorAluguel: 0,
        valorCondominio: 0,
        valorAgua: 0,
        valorLuz: 0,
        valorIptu: 0,
        taxaSetup: 0,
        pacoteId: null,
        cepLocacao: null,
        numeroResidenciaLocacao: null,
        logradouroLocacao: null,
        bairroLocacao: null,
        cidadeLocacao: null,
        ufLocacao: null,

        complemento: null,
        complementoLocacao: null,
        tipoContrato: null,
      },
      erros: {
        nome: null,
        email: null,
        cpf: null,
        rg: null,
        dataNascimento: null,
        ddd: null,
        telefone: null,
        cep: null,
        numeroResidencia: null,
        logradouro: null,
        bairro: null,
        cidade: null,
        uf: null,
        valorAluguel: null,
        valorCondominio: null,
        valorAgua: null,
        valorLuz: null,
        valorIptu: null,
        taxaSetup: null,
        empresaId: null,
        pacoteId: null,
        cepLocacao: null,
        numeroResidenciaLocacao: null,
        logradouroLocacao: null,
        bairroLocacao: null,
        cidadeLocacao: null,
        ufLocacao: null,

        complemento: null,
        complementoLocacao: null,
        tipoContrato: null,
      },
    };
  },
  computed: {
    tipoSubmit() {
      return this.$route.meta.submit;
    },
    tituloPagina() {
      if (this.tipoSubmit === "cadastrar") {
        return "Cadastrar Cliente";
      }
      return "Editar Cliente";
    },
    habilitacaoSubmit() {
      return !this.loading && !this.notificacao.status;
    },
    iconeErro() {
      return "mdi-alert-octagram";
    },
    itensSelect() {
      const ufs = helpers.obterUfs();
      const itensPacotes = this.pacotes.map((pacote) => {
        const nome = pacote.nome;
        const porcentagem = helpers.numericoParaMonetarioBrasil(pacote.porcentagem) + "%";
        const metodo = this.traduzirMetodoPagamento(pacote);
        const qtdParcelas = pacote.quantidadeParcelas + " vez(es)";

        let descricao = `${nome}  -  ${porcentagem} a pagar  -  ${metodo}`;

        if (pacote.tipoPagamento === "cartao") {
          descricao += `  -  ${qtdParcelas}`;
        }

        return {
          valor: Number(pacote.id),
          label: descricao,
        };
      });

      return {
        ufs,
        pacotes: itensPacotes,
      };
    },
    blockBtns() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    permissaoPagina() {
      if (this.tipoSubmit === "cadastrar") return true;
      const empresaLogada = this.usuarioLogado.empresaId;
      return this.clienteEncontrado && (this.permissaoMaster || empresaLogada === this.idEmpresa);
    },
    existemPacotes() {
      return this.pacotes.filter((pacote) => !pacote.excluido).length > 0;
    },
    mascaraTelefone() {
      const telefone = this.formulario.telefone;

      if (!telefone) return;

      const tamanho = telefone.replace("-", "").length;

      if (tamanho <= 7) return "###-####";
      if (tamanho == 8) return "####-####";

      return "#####-####";
    },
    configMonetario() {
      return {
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: false,
      };
    },
    pacoteAtual() {
      const idAtual = this.formulario.pacoteId;

      return this.pacotes.find((pacote) => {
        return pacote.id === idAtual && !pacote.excluido;
      });
    },
    valorTotal() {
      const aluguel = helpers.monetarioBrasilParaNumerico(this.formulario.valorAluguel);
      const condominio = helpers.monetarioBrasilParaNumerico(this.formulario.valorCondominio);
      const agua = helpers.monetarioBrasilParaNumerico(this.formulario.valorAgua);
      const luz = helpers.monetarioBrasilParaNumerico(this.formulario.valorLuz);
      const iptu = helpers.monetarioBrasilParaNumerico(this.formulario.valorIptu);
      const taxaSetup = this.pacoteAtual?.taxasSetup?.find((taxa) => taxa.valor === this.formulario.taxaSetup
          || taxa.id === this.formulario.taxaSetup)?.valor ?? 0.00;
      const soma = aluguel + condominio + agua + luz + iptu;

      //TODO: Verificar se o valor total será calculado em 12 fixas ou pela quantidade de parcelas_garantia
      const resultado = (soma * 12) + taxaSetup;

      return helpers.numericoParaMonetarioBrasil(resultado);
    },
    valorTotalComPacote() {
      if (!this.pacoteAtual || this.valorTotal <= 0) return 0;
      let taxaSetup = this.pacoteAtual?.taxasSetup?.find((taxa) => taxa.valor === this.formulario.taxaSetup
          || taxa.id === this.formulario.taxaSetup)?.valor ?? 0.00;
      if (!this.valorTotal && taxaSetup > 0.00) return taxaSetup;

      const total = helpers.monetarioBrasilParaNumerico(this.valorTotal);
      const porcentagem = Number(this.pacoteAtual.porcentagem);

      const totalSemTaxa = total - taxaSetup;
      const resultadoSemTaxa = totalSemTaxa * (porcentagem / 100);
      const final = resultadoSemTaxa + taxaSetup;

      return helpers.numericoParaMonetarioBrasil(final);
    },
  },
  methods: {
    buscarCliente() {
      const id = this.$route.params.id;
      const cliente = this.clientes.find((c) => Number(c.id) === Number(id));

      if (!cliente) {
        this.clienteEncontrado = false;
      } else {
        this.idCliente = cliente.id;
        this.idEmpresa = Number(cliente.empresaId);
        this.formulario.nome = cliente.nome;
        this.formulario.email = cliente.email;
        this.formulario.cpf = cliente.cpf;
        this.formulario.rg = cliente.rg;
        this.formulario.dataNascimento = helpers.formatarDataBrasil(cliente.dataNascimento);
        this.formulario.ddd = cliente.ddd;
        this.formulario.telefone = cliente.telefone;
        this.formulario.cep = cliente.cep;
        this.formulario.numeroResidencia = cliente.numeroResidencia;
        this.formulario.logradouro = cliente.logradouro;
        this.formulario.bairro = cliente.bairro;
        this.formulario.cidade = cliente.cidade;
        this.formulario.uf = cliente.uf;
        this.formulario.valorAluguel = helpers.numericoParaMonetarioBrasil(cliente.valorAluguel);
        this.formulario.valorCondominio = helpers.numericoParaMonetarioBrasil(cliente.valorCondominio);
        this.formulario.valorAgua = helpers.numericoParaMonetarioBrasil(cliente.valorAgua);
        this.formulario.valorLuz = helpers.numericoParaMonetarioBrasil(cliente.valorLuz);
        this.formulario.valorIptu = helpers.numericoParaMonetarioBrasil(cliente.valorIptu);
        this.formulario.cepLocacao = cliente.cepLocacao;
        this.formulario.numeroResidenciaLocacao = cliente.numeroResidenciaLocacao;
        this.formulario.logradouroLocacao = cliente.logradouroLocacao;
        this.formulario.bairroLocacao = cliente.bairroLocacao;
        this.formulario.cidadeLocacao = cliente.cidadeLocacao;
        this.formulario.ufLocacao = cliente.ufLocacao;
        this.formulario.pacoteId = cliente.venda_atual.pacote_id;
        // this.formulario.taxaSetup = helpers.numericoParaMonetarioBrasil(cliente.venda_atual.taxa_setup);

        this.formulario.complemento = cliente.complemento;
        this.formulario.complementoLocacao = cliente.complementoLocacao;
        this.formulario.tipoContrato = cliente.tipoContrato;

      }
    },
    confirmarSaida() {
      this.$confirm("Deseja ser direcionado para lista de Clientes ?").then((res) => {
        if (res) {
          this.navegar("clientesIndex");
        }
      })
    },
    traduzirMetodoPagamento(pacote) {
      if (pacote.tipoPagamento === "cartao") return "Cartão de Crédito";
      if (pacote.tipoPagamento === "boleto") return "Boleto Bancário";
      return "Dinheiro";
    },
    submit() {
      // if (!this.habilitacaoSubmit) return;
      if (this.tipoSubmit === "cadastrar") return this.cadastrar();
      return this.atualizar();
    },
    async cadastrar() {
      this.erros = {};
      this.ativarLoading();
      try {
        await clientesServices.cadastrar(this.formulario);
        this.notificacaoSucesso("Cliente Cadastrado");
        this.navegar("clientesIndex");
      } catch (e) {
        this.interpretarErro(e);
        if (this.obterCodigoRequisicao(e) === 422) {
          this.apresentarErros(e.response.data.erros);
        }
      } finally {
        this.desativarLoading();
      }
    },
    async atualizar() {
      this.erros = {};
      this.ativarLoading();
      try {
        await clientesServices.editar(this.idCliente, this.formulario);
        this.notificacaoSucesso("Cliente Editado");
        this.navegar("clientesIndex");
      } catch (e) {
        this.interpretarErro(e);
        if (this.obterCodigoRequisicao(e) === 422) {
          this.apresentarErros(e.response.data.erros);
        }
      } finally {
        this.desativarLoading();
      }
    },
    apresentarErros(erros) {
      if (!helpers.empty(erros.nome)) this.erros.nome = erros.nome[0];
      if (!helpers.empty(erros.email)) this.erros.email = erros.email[0];
      if (!helpers.empty(erros.cpf)) this.erros.cpf = erros.cpf[0];
      if (!helpers.empty(erros.rg)) this.erros.rg = erros.rg[0];
      if (!helpers.empty(erros.dt_nascimento)) this.erros.dataNascimento = erros.dt_nascimento[0];
      if (!helpers.empty(erros.ddd)) this.erros.ddd = erros.ddd[0];
      if (!helpers.empty(erros.telefone)) this.erros.telefone = erros.telefone[0];
      if (!helpers.empty(erros.cep)) this.erros.cep = erros.cep[0];
      if (!helpers.empty(erros.numero_residencia)) this.erros.numeroResidencia = erros.numero_residencia[0];
      if (!helpers.empty(erros.logradouro)) this.erros.logradouro = erros.logradouro[0];
      if (!helpers.empty(erros.bairro)) this.erros.bairro = erros.bairro[0];
      if (!helpers.empty(erros.cidade)) this.erros.cidade = erros.cidade[0];
      if (!helpers.empty(erros.uf)) this.erros.uf = erros.uf[0];
      if (!helpers.empty(erros.valor_aluguel)) this.erros.valorAluguel = erros.valor_aluguel[0];
      if (!helpers.empty(erros.valor_condominio)) this.erros.valorCondominio = erros.valor_condominio[0];
      if (!helpers.empty(erros.valor_agua)) this.erros.valorAgua = erros.valor_agua[0];
      if (!helpers.empty(erros.valor_luz)) this.erros.valorLuz = erros.valor_luz[0];
      if (!helpers.empty(erros.valor_iptu)) this.erros.valorIptu = erros.valor_iptu[0];
      if (!helpers.empty(erros.taxa_setup)) this.erros.taxaSetup = erros.taxa_setup[0];
      if (!helpers.empty(erros.pacote_id)) this.erros.pacoteId = erros.pacote_id[0];
      if (!helpers.empty(erros.cep_locacao)) this.erros.cepLocacao = erros.cep_locacao[0];
      if (!helpers.empty(erros.numero_residencia_locacao))
        this.erros.numeroResidenciaLocacao = erros.numero_residencia_locacao[0];
      if (!helpers.empty(erros.logradouro_locacao)) this.erros.logradouroLocacao = erros.logradouro_locacao[0];
      if (!helpers.empty(erros.bairro_locacao)) this.erros.bairroLocacao = erros.bairro_locacao[0];
      if (!helpers.empty(erros.cidade_locacao)) this.erros.cidadeLocacao = erros.cidade_locacao[0];
      if (!helpers.empty(erros.uf_locacao)) this.erros.ufLocacao = erros.uf_locacao[0];

      if (!helpers.empty(erros.complemento)) this.erros.complemento = erros.complemento[0];
      if (!helpers.empty(erros.complemento_locacao)) this.erros.complementoLocacao = erros.complemento_locacao[0];
      if (!helpers.empty(erros.tipoContrato)) this.erros.tipoContrato = erros.tipo_contrato[0];
    },
    async buscarCepLocatario() {
      const regexCep = /^\d{1,5}-\d{3}$/;
      if (!regexCep.test(this.formulario.cep)) return;

      this.ativarLoading();
      try {
        // let response = await viaCepServices.buscar(this.formulario.cep);
        let response = await brasilApiCep.buscar(this.formulario.cep);
        let status = helpers.isset(() => response.status) ? response.status : false;

        if (status === 200 && !response.data.erro) {
          this.formulario.uf = response.data.state ?? response.data.uf;
          this.formulario.logradouro = response.data.street ?? response.data.logradouro;
          this.formulario.bairro = response.data.neighborhood ?? response.data.bairro;
          this.formulario.cidade = response.data.city ?? response.data.localidade;
        } else {
          this.formulario.cep = null;
          this.setNotificacao({
            status: true,
            mensagem: "CEP não encontrado, verifique o CEP e preencha novamente...",
            categoria: 404,
          });
        }
      } catch (error) {
        this.formulario.cep = null;
        this.setNotificacao({
          status: true,
          mensagem: "CEP não encontrado, verifique o CEP e preencha novamente...",
          categoria: 503,
        });
      } finally {
        this.desativarLoading();
      }
    },
    async buscarCepLocacao() {
      const regexCep = /^\d{1,5}-\d{3}$/;
      if (!regexCep.test(this.formulario.cepLocacao)) return;

      this.ativarLoading();
      try {
        // let response = await viaCepServices.buscar(this.formulario.cepLocacao);
        let response = await brasilApiCep.buscar(this.formulario.cepLocacao);
        let status = helpers.isset(() => response.status) ? response.status : false;

        if (status === 200 && !response.data.erro) {
          this.formulario.ufLocacao = response.data.state ?? response.data.uf;
          this.formulario.logradouroLocacao = response.data.street ?? response.data.logradouro;
          this.formulario.bairroLocacao = response.data.neighborhood ?? response.data.bairro;
          this.formulario.cidadeLocacao = response.data.city ?? response.data.localidade;
        } else {
          this.formulario.cepLocacao = null;
          this.setNotificacao({
            status: true,
            mensagem: "CEP não encontrado, verifique o CEP e preencha novamente...",
            categoria: 404,
          });
        }
      } catch (error) {
        this.formulario.cepLocacao = null;
        this.setNotificacao({
          status: true,
          mensagem: "CEP não encontrado, verifique o CEP e preencha novamente...",
          categoria: 503,
        });
      } finally {
        this.desativarLoading();
      }
    },
  },
  watch: {},
  created() {
    if (this.tipoSubmit === "editar") {
      this.buscarCliente();
    }
  },
};
</script>

import axios from 'axios';

const brasilAPI = axios.create({
    baseURL: "https://brasilapi.com.br/api/cep/v2/",
    timeout: 10000,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});

const viaCEP = axios.create({
    baseURL: "https://viacep.com.br/ws/",
    timeout: 10000,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});

const parseViaCEP = (data) => {
    return {
        "cep": data.cep,
        "state": data.uf,
        "city": data.localidade,
        "neighborhood": data.bairro,
        "street": data.logradouro,
    };
};

const buscar = async (cep) => {
    let uri = `/${cep}`;
    let response;
    try {
        response = await brasilAPI.get(uri);
    } catch (e) {
        response = await viaCEP.get(uri+'/json');
        response.data = parseViaCEP(response.data);
    }

    return response;
};

export default {
    buscar
};
